<template>
	<div class="qywx-login">
		<!-- Qywx
		userId: {{userId}}
		message: <el-input v-model="text"></el-input>
		<el-button @click="sendMsg">QywxSendMsg</el-button>
		<el-button @click="sendMiniProgram">QywxSendMiniProgram</el-button> -->
		<div class="title">
			<p class="text">欢迎登录！</p>
			<p class="name">解放轻卡备品商城</p>
		</div>
		<el-form ref="form" :model="form" v-if="needLogin" class="login-form">
			<el-form-item label="">
				<el-input prefix-icon="el-icon-user" v-model="form.mobile" placeholder="请输入手机号"></el-input>
			</el-form-item>
			<el-form-item label="">
				<el-input prefix-icon="el-icon-lock" type="password" v-model="form.password" placeholder="请输入密码"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="onSubmit" class="login-btn">登录</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import * as QywxApi from '@/api/qywx'
	import * as LoginApi from '@/api/login'
	import config from './config'
	import {
		checkRedirect,
		initSdk,
		invoke,
		asyncCall,
		call
	} from 'wecom-sidebar-jssdk';
	import Login from "../login/login.vue";

	export default {
		components: {

		},
		data() {
			return {
				text: '',
				needLogin: false,
				form: {
					mobile: '',
					password: '',
					isParty: false,
					partyData: []
				},
			};
		},
		created() {
			localStorage.removeItem('customerId')
			checkRedirect(config, this.fetchUserId)
				.then(() => initSdk(config, this.fetchSignatures))
				.then(() => this.getUserId())
		},
		methods: {
			async fetchSignatures() {
                let url = window.location.href
                let qywxUserId = localStorage.getItem('qywxUserId')
                if (url.split('?').length > 1 && qywxUserId) {
                    url = url.split('?')[0]
                    window.location.replace(url)
                }
				let res = await QywxApi.getSignatures({
					url: url
				});
				let signatures = res.data.signatures
				return signatures
			},
			async fetchUserId(code) {
				let res = await QywxApi.getQywxUserId({
					code: code
				});
				let qywxUserId = res.data.userid
				localStorage.setItem('qywxUserId', qywxUserId)
				return qywxUserId;
			},
			async getCustomerId() {
				try {
					const res = await invoke('getContext');
					console.log(res);

					const res1 = await invoke('getCurExternalContact')
					if (res1.err_msg == "getCurExternalContact:ok") {
						let customerId = res1.userId;
						localStorage.setItem('customerId', customerId)
						QywxApi.getUserInfoByQywxUserId({
							qywxUserId: customerId
						}).then(response => {
							let customerUser = response.data
							if (customerUser && customerUser.userId != null) {
								localStorage.setItem('customerUserId', customerUser.userId)
							}
							this.$router.push('/qywx/custom')
						})
					} else {
						this.$message({
							message: '获取客户信息失败！',
							type: 'error'
						});
					}
				} catch (e) {
					console.log(e.message);
				}
			},
			async getUserId() {
				let userId = localStorage.getItem('userId')
				if (!userId) {
					this.needLogin = true
				} else {
					// this.getCustomerId()
          this.$router.push('/qywx/custom')
				}
			},
			onSubmit() {
				LoginApi.pwdLogin({
					form: this.form
				}).then(response => {
					console.log(response)
					localStorage.setItem('token', response.data.token)
					localStorage.setItem('userId', response.data.userId)
					localStorage.setItem('mobile', this.form.mobile)
					this.$message({
						message: response.message,
						type: 'success'
					});
					this.needLogin = false
					this.getCustomerId()
				}).catch(err => {
					this.$messag.error(err.message)
					console.log(err)
				})
			},
			async sendMsg() {
				const res1 = await invoke('sendChatMessage', {
					msgtype: "text", //消息类型，必填
					enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
					text: {
						content: this.text, //文本内容
					}
				})
				console.log(res1)
			},
			async sendMiniProgram() {
				const res1 = await invoke('sendChatMessage', {
					msgtype: "miniprogram", //消息类型，必填
					enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
					miniprogram: {
						appid: "wxabff3308097a0afe", //小程序的appid，企业已关联的任一个小程序
						title: "咔咔修", //小程序消息的title
						imgUrl: "https://search-operate.cdn.bcebos.com/d054b8892a7ab572cb296d62ec7f97b6.png", //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
						page: "/index/page.html",
					}
				})
				console.log(res1)
			}
		}

	}
</script>

<style lang="scss" scoped>
.qywx-login{
	height: 100vh;
	background-image: linear-gradient(to bottom,#208aef,#2facfc)!important;
	padding:30vh 0.5rem 0 0.5rem;
	color: #FFF;
	.title{
		font-size: 0.5rem;
		margin: 0 0 0.7rem 0;
		// font-weight: bold;
		.text{
			margin: 0 0 0.1rem 0;
		}
	}
	::v-deep.login-form{
		.el-input{
			.el-input__inner{
				background-color: inherit;
				border-radius:40px;
				border: 1px solid #FFF;
				color: #FFF;
				padding-left: 50px;
				font-size: 16px;
				&::placeholder{
					color: #FFF;
				}
			}
			.el-input__prefix{
				left: 15px;
				top: 2px;
				i{
					font-size: 24px;
					color: #FFF;
				}
			}
		}
		.login-btn{
			width: 100%;
			border-radius: 40px;
			background-color: #FF7723;
			border: none;
			font-size: 18px;
			margin: 0.4rem 0 0 0;
		}
	}
}
</style>
