<template>
	<div class="qywx-login">
		<!-- Qywx
		userId: {{userId}}
		message: <el-input v-model="text"></el-input>
		<el-button @click="sendMsg">QywxSendMsg</el-button>
		<el-button @click="sendMiniProgram">QywxSendMiniProgram</el-button> -->
		<div class="title">
			<p class="text">欢迎登录！</p>
			<p class="name">快智配查询</p>
		</div>
		<el-form ref="form" :model="form" v-if="needLogin" class="login-form">
			<el-form-item label="">
				<el-input prefix-icon="el-icon-user" v-model="form.mobile" placeholder="请输入手机号"></el-input>
			</el-form-item>
			<el-form-item label="">
				<el-input prefix-icon="el-icon-lock" type="password" v-model="form.password" placeholder="请输入密码"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="onSubmit" class="login-btn">登录</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import * as LoginApi from '@/api/login'

	export default {
		components: {

		},
		data() {
			return {
				text: '',
				needLogin: false,
				form: {
					mobile: '',
					password: '',
					isParty: false,
					partyData: []
				},
			};
		},
		created() {
			this.getUserId()
		},
		methods: {
			async getUserId() {
				let userId = localStorage.getItem('userId')
				if (!userId) {
					this.needLogin = true
				} else {
          this.goNextPage()
        }
			},
      goNextPage() {
        this.$router.push('/qywx-bd/placeOrder')
      },
			onSubmit() {
				LoginApi.pwdLogin({
					form: this.form
				}).then(response => {
					console.log(response)
					localStorage.setItem('token', response.data.token)
					localStorage.setItem('userId', response.data.userId)
					localStorage.setItem('mobile', this.form.mobile)
					this.$message({
						message: response.message,
						type: 'success'
					});
					this.needLogin = false
          this.goNextPage()
				}).catch(err => {
					this.$messag.error(err.message)
					console.log(err)
				})
			},
		}

	}
</script>

<style lang="scss" scoped>
.qywx-login{
	height: 100vh;
	background-image: linear-gradient(to bottom,#208aef,#2facfc)!important;
	padding:30vh 0.5rem 0 0.5rem;
	color: #FFF;
	.title{
		font-size: 0.5rem;
		margin: 0 0 0.7rem 0;
		// font-weight: bold;
		.text{
			margin: 0 0 0.1rem 0;
		}
	}
	::v-deep.login-form{
		.el-input{
			.el-input__inner{
				background-color: inherit;
				border-radius:40px;
				border: 1px solid #FFF;
				color: #FFF;
				padding-left: 50px;
				font-size: 16px;
				&::placeholder{
					color: #FFF;
				}
			}
			.el-input__prefix{
				left: 15px;
				top: 2px;
				i{
					font-size: 24px;
					color: #FFF;
				}
			}
		}
		.login-btn{
			width: 100%;
			border-radius: 40px;
			background-color: #FF7723;
			border: none;
			font-size: 18px;
			margin: 0.4rem 0 0 0;
		}
	}
}
</style>
